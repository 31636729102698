.AppContainer {
    background-color: #282c34;
    text-align: center;
    background-image: url("./resources/rsvpBackgroundImage.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.RsvpSiteTitle {
    font-style: italic;
    margin-top: 16px;
    font-size: 17px;
    color: white;
    height: 32px;
    text-align: center;
    line-height: 39px;
    float: left;
}

.RsvpHeaderContainer {
    width: 100%;
    height: 100%;
}

.RsvpHeaderClear {
    width: 100%;
    position: fixed;
    background-color: Transparent;
    height: 54px;
    z-index: 10;
}

.RsvpHeader {
    width: 100%;
    position: fixed;
    background-color: Transparent;
    height: 54px;
    z-index: 10;
}

@media only screen and (max-width: 1000px) {
    .RsvpHeader {
        background-color: #282c34CD;
    }
}

.RsvpHomeButton {
    background-color: Transparent;
    width: 60px;
    float: left;
    margin-top: 16px;
    padding: 0;
}

.Rsvp {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 0;
}

.RsvpBody {
    padding-top: 54px;
    display: flex;
    color: white;
    width: 100%;
    min-height: calc(100% - 54px);
}

.RsvpCenterColumnSpan {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin: auto;
    align-items: center;
}

.RsvpContent {
    margin: auto;
    width: calc(100% - 32px);
    max-width: 540px;
    min-width: 300px;
}

.RsvpSubmitButton {
    left: 16px;
    right: 16px;
    bottom: 16px;
    width: calc(100% - 32px);
    max-width: 540px;
    min-width: 300px;
    height: 34px;
    margin: 16px;
    margin-top: 8px;
    margin-bottom: 2px;
    border-radius: 6px;
    padding: 5px 10px;
}

.WebsiteLinkButton {
    color: #FFFFFFCC;
    background-color: transparent;
    text-decoration: underline;
    left: 16px;
    right: 16px;
    bottom: 16px;
    width: 100vw;
    max-width: 540px;
    min-width: 300px;
    height: 32px;
    margin: 16px;
    margin-top: 32px;
    margin-bottom: 32px;
    border-radius: 6px;
    font-size: 17px;
}

.MoreInfoButton {
    color: #FFFFFFCC;
    background-color: transparent;
    text-decoration: underline;
    left: 16px;
    right: 16px;
    bottom: 16px;
    width: 100vw;
    max-width: 540px;
    min-width: 300px;
    height: 32px;
    margin: 16px;
    margin-top: 8px;
    margin-bottom: 32px;
    border-radius: 6px;
    font-size: 15px;
}

.RsvpHidden {
    display: none;
}

@keyframes Rsvp-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.RsvpSuccessMessage {
    text-align: center;
    font-size: 21px;
}

.RsvpSuccessPageCalendarButton {
     left: 16px;
     right: 16px;
     bottom: 16px;
     width: 288px;
     height: 34px;
     margin: 16px;
     margin-bottom: 0;
     margin-top: 32px;
     border-radius: 6px;
     padding: 5px 10px;
}

.RsvpSuccessPageDescriptionMessage {
    margin-top: 8px;
    text-align: center;
    font-size: 12px;
}

.RsvpErrorMessage {
    text-align: center;
    font-size: 21px;
}

.RsvpActivityIndicator {
    border: 16px solid #3E444EDC;
    border-top: 16px solid #7530BCDC;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 3s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

button, select {
    color: white;
    background-color: #7530BCDC;
    border: none;
    font-size: 17px;
    cursor: pointer;
    outline: none;
    border-radius: 0;
}

.RsvpLandingPageSubmitButton {
    left: 16px;
    right: 16px;
    bottom: 16px;
    width: 288px;
    height: 34px;
    margin-top: 32px;
    margin: 16px;
    border-radius: 6px;
    padding: 5px 10px;
}

.RsvpLandingPageTextField {
    height: 28px;
    width: 272px;
    margin: 16px;
    border-radius: 6px;
    border-width: 1px;
    outline: none;
    border-style: solid;
    font-size: 15px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 24px;
}

.RsvpLandingPageTextField:focus {
    border-color: #7530BCDC;
}

.RsvpLandingPageMessage {
    text-align: center;
    font-size: 21px;
}
