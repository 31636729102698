.DCCheckboxButton {
    background-color: Transparent;
    text-align: left;
    padding: 0;
    margin: 0;
}

.DCCheckboxTitle {
    margin-right: 8px;
    vertical-align: middle;
    font-size: 15px;
}

.DCCheckboxImage {
    width: 18px;
    height: 18px;
    vertical-align: middle;
}