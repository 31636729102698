@import url('https://fonts.googleapis.com/css?family=Poiret+One&display=swap');

.invite {
    background-image: linear-gradient(#FBD6EB, #FFBEE5);
    background-color:#FFBEE5;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.inviteContainer {
    position: relative;
    text-align: center;
    color: white;
    width: 100%;
}

.inviteCentered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.inviteSpacer
{
    height: 60px;
}

.inviteCenterBlock {
    width: 100%;
}

.inviteBannerImage {
    width: 100%;
    margin-bottom: 20px;
}

.inviteTable {
    width: 100%;
}

.inviteHeadlineFont {
    font-weight: 500;
    font-size: 30px;
    font-size: 5.1vw;
    color: black;
    font-family: 'Poiret One', Helvetica, Verdana, Tahoma, sans-serif;
    text-align: center;
}

.inviteHeadlineFontLarge {
    font-weight: bold;
    font-size: 48px;
    font-size: 8.5vw;
    color: black;
    font-family: 'Poiret One', Helvetica, Verdana, Tahoma, sans-serif;
    text-align: center;
}

.inviteDateBlock {
    border-style: dashed none dashed none;
}

.inviteTimeBlock {
    border-style: dashed none dashed none;
}

.inviteLocationBlock {
    border-style: dashed;
}

.inviteRsvpBlock {
    border-style: none none dashed none;
}

.inviteRsvpInnerBlock {
    width: 100%;
    height: 100%;
}

th, td {
    padding: 18px;
    border-color: #72253065;
    border-width: 2px;
    color: #000000;
    font-size: 21px;
    font-size: 3.5vw;
    font-family: 'Poiret One', Helvetica, Verdana, Tahoma, sans-serif;
}

a {
    color: #000000;
}