@import url(https://fonts.googleapis.com/css?family=Poiret+One&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.AppContainer {
    background-color: #282c34;
    text-align: center;
    background-image: url(/static/media/rsvpBackgroundImage.fb21a02c.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.RsvpSiteTitle {
    font-style: italic;
    margin-top: 16px;
    font-size: 17px;
    color: white;
    height: 32px;
    text-align: center;
    line-height: 39px;
    float: left;
}

.RsvpHeaderContainer {
    width: 100%;
    height: 100%;
}

.RsvpHeaderClear {
    width: 100%;
    position: fixed;
    background-color: Transparent;
    height: 54px;
    z-index: 10;
}

.RsvpHeader {
    width: 100%;
    position: fixed;
    background-color: Transparent;
    height: 54px;
    z-index: 10;
}

@media only screen and (max-width: 1000px) {
    .RsvpHeader {
        background-color: #282c34CD;
    }
}

.RsvpHomeButton {
    background-color: Transparent;
    width: 60px;
    float: left;
    margin-top: 16px;
    padding: 0;
}

.Rsvp {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 0;
}

.RsvpBody {
    padding-top: 54px;
    display: -webkit-flex;
    display: flex;
    color: white;
    width: 100%;
    min-height: calc(100% - 54px);
}

.RsvpCenterColumnSpan {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: auto;
    -webkit-align-items: center;
            align-items: center;
}

.RsvpContent {
    margin: auto;
    width: calc(100% - 32px);
    max-width: 540px;
    min-width: 300px;
}

.RsvpSubmitButton {
    left: 16px;
    right: 16px;
    bottom: 16px;
    width: calc(100% - 32px);
    max-width: 540px;
    min-width: 300px;
    height: 34px;
    margin: 16px;
    margin-top: 8px;
    margin-bottom: 2px;
    border-radius: 6px;
    padding: 5px 10px;
}

.WebsiteLinkButton {
    color: #FFFFFFCC;
    background-color: transparent;
    text-decoration: underline;
    left: 16px;
    right: 16px;
    bottom: 16px;
    width: 100vw;
    max-width: 540px;
    min-width: 300px;
    height: 32px;
    margin: 16px;
    margin-top: 32px;
    margin-bottom: 32px;
    border-radius: 6px;
    font-size: 17px;
}

.MoreInfoButton {
    color: #FFFFFFCC;
    background-color: transparent;
    text-decoration: underline;
    left: 16px;
    right: 16px;
    bottom: 16px;
    width: 100vw;
    max-width: 540px;
    min-width: 300px;
    height: 32px;
    margin: 16px;
    margin-top: 8px;
    margin-bottom: 32px;
    border-radius: 6px;
    font-size: 15px;
}

.RsvpHidden {
    display: none;
}

@-webkit-keyframes Rsvp-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes Rsvp-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.RsvpSuccessMessage {
    text-align: center;
    font-size: 21px;
}

.RsvpSuccessPageCalendarButton {
     left: 16px;
     right: 16px;
     bottom: 16px;
     width: 288px;
     height: 34px;
     margin: 16px;
     margin-bottom: 0;
     margin-top: 32px;
     border-radius: 6px;
     padding: 5px 10px;
}

.RsvpSuccessPageDescriptionMessage {
    margin-top: 8px;
    text-align: center;
    font-size: 12px;
}

.RsvpErrorMessage {
    text-align: center;
    font-size: 21px;
}

.RsvpActivityIndicator {
    border: 16px solid #3E444EDC;
    border-top: 16px solid #7530BCDC;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 3s linear infinite;
            animation: spin 3s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

button, select {
    color: white;
    background-color: #7530BCDC;
    border: none;
    font-size: 17px;
    cursor: pointer;
    outline: none;
    border-radius: 0;
}

.RsvpLandingPageSubmitButton {
    left: 16px;
    right: 16px;
    bottom: 16px;
    width: 288px;
    height: 34px;
    margin-top: 32px;
    margin: 16px;
    border-radius: 6px;
    padding: 5px 10px;
}

.RsvpLandingPageTextField {
    height: 28px;
    width: 272px;
    margin: 16px;
    border-radius: 6px;
    border-width: 1px;
    outline: none;
    border-style: solid;
    font-size: 15px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 24px;
}

.RsvpLandingPageTextField:focus {
    border-color: #7530BCDC;
}

.RsvpLandingPageMessage {
    text-align: center;
    font-size: 21px;
}

.PersonFormRow {
    background-color: #3E444EDC;
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 6px;
}

.PersonFormRowContentContainer {
    text-align: left;
    width: 320px;
    font-size: 21px;
}

.PersonFormRowNameLabel {
    font-size: 21px;
    margin-bottom: 4px;
    font-weight: bold;
}

.PersonFormRowCheckboxContainer {
    margin-bottom: 4px;
}

/*.PersonFormRowCheckboxLabel {*/
    /*font-size: 15px;*/
/*}*/

/*.PersonFormRowCheckBoxInput {*/
    /*margin-left: 8px;*/
/*}*/

.PersonFormRowDCCheckbox {
    margin-left: 8px;
}

.PersonFormRowDietaryTextBoxContainer {
    margin-right: 7px;
    width: calc(50% - 10px);
}

.PersonFormRowDietaryTextBoxLabel {
    text-align: left;
    font-size: 15px;
    margin-bottom: 4px;
}

.PersonFormRowDietaryTextBox {
    width: 100%;
    height: 136px;
    resize: none;
    font-size: 13px;
    border-width: 1px;
    border-radius: 4px;
    border-style: solid;
    outline: none;
}

.PersonFormRowDietaryTextBox:focus {
    border-color: #7530BCDC;
}

.PersonFormRowCommentsTextBoxContainer {
    margin-left: 7px;
    width: calc(50% - 10px);
}

.PersonFormRowCommentsTextBoxLabel {
    text-align: left;
    font-size: 15px;
    margin-bottom: 4px;
}

.PersonFormRowCommentsTextBox {
    width: 100%;
    height: 136px;
    resize: none;
    font-size: 13px;
    border-width: 1px;
    border-radius: 4px;
    border-style: solid;
    outline: none;
}

.PersonFormRowCommentsTextBox:focus {
    border-color: #7530BCDC;
}

.PersonFormRowHidden {
     display: none;
}

.PersonFormRowTextBoxesContainer {
    margin-top: 10px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    padding: 0;
}

@media only screen and (max-width: 416px) {
    .PersonFormRowTextBoxesContainer {
        display: block;
        padding-right: 8px;
    }
    .PersonFormRowDietaryTextBoxContainer {
        margin-right: 0;
        width: calc(100% - 6px);
    }
    .PersonFormRowDietaryTextBox {
        height: 100px;
    }
    .PersonFormRowCommentsTextBoxContainer {
        margin-left: 0;
        width: calc(100% - 6px);
    }
    .PersonFormRowCommentsTextBox {
        height: 100px;
    }
}


.DCCheckboxButton {
    background-color: Transparent;
    text-align: left;
    padding: 0;
    margin: 0;
}

.DCCheckboxTitle {
    margin-right: 8px;
    vertical-align: middle;
    font-size: 15px;
}

.DCCheckboxImage {
    width: 18px;
    height: 18px;
    vertical-align: middle;
}
.invite {
    background-image: -webkit-linear-gradient(#FBD6EB, #FFBEE5);
    background-image: linear-gradient(#FBD6EB, #FFBEE5);
    background-color:#FFBEE5;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.inviteContainer {
    position: relative;
    text-align: center;
    color: white;
    width: 100%;
}

.inviteCentered {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.inviteSpacer
{
    height: 60px;
}

.inviteCenterBlock {
    width: 100%;
}

.inviteBannerImage {
    width: 100%;
    margin-bottom: 20px;
}

.inviteTable {
    width: 100%;
}

.inviteHeadlineFont {
    font-weight: 500;
    font-size: 30px;
    font-size: 5.1vw;
    color: black;
    font-family: 'Poiret One', Helvetica, Verdana, Tahoma, sans-serif;
    text-align: center;
}

.inviteHeadlineFontLarge {
    font-weight: bold;
    font-size: 48px;
    font-size: 8.5vw;
    color: black;
    font-family: 'Poiret One', Helvetica, Verdana, Tahoma, sans-serif;
    text-align: center;
}

.inviteDateBlock {
    border-style: dashed none dashed none;
}

.inviteTimeBlock {
    border-style: dashed none dashed none;
}

.inviteLocationBlock {
    border-style: dashed;
}

.inviteRsvpBlock {
    border-style: none none dashed none;
}

.inviteRsvpInnerBlock {
    width: 100%;
    height: 100%;
}

th, td {
    padding: 18px;
    border-color: #72253065;
    border-width: 2px;
    color: #000000;
    font-size: 21px;
    font-size: 3.5vw;
    font-family: 'Poiret One', Helvetica, Verdana, Tahoma, sans-serif;
}

a {
    color: #000000;
}
