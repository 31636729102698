.PersonFormRow {
    background-color: #3E444EDC;
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 6px;
}

.PersonFormRowContentContainer {
    text-align: left;
    width: 320px;
    font-size: 21px;
}

.PersonFormRowNameLabel {
    font-size: 21px;
    margin-bottom: 4px;
    font-weight: bold;
}

.PersonFormRowCheckboxContainer {
    margin-bottom: 4px;
}

/*.PersonFormRowCheckboxLabel {*/
    /*font-size: 15px;*/
/*}*/

/*.PersonFormRowCheckBoxInput {*/
    /*margin-left: 8px;*/
/*}*/

.PersonFormRowDCCheckbox {
    margin-left: 8px;
}

.PersonFormRowDietaryTextBoxContainer {
    margin-right: 7px;
    width: calc(50% - 10px);
}

.PersonFormRowDietaryTextBoxLabel {
    text-align: left;
    font-size: 15px;
    margin-bottom: 4px;
}

.PersonFormRowDietaryTextBox {
    width: 100%;
    height: 136px;
    resize: none;
    font-size: 13px;
    border-width: 1px;
    border-radius: 4px;
    border-style: solid;
    outline: none;
}

.PersonFormRowDietaryTextBox:focus {
    border-color: #7530BCDC;
}

.PersonFormRowCommentsTextBoxContainer {
    margin-left: 7px;
    width: calc(50% - 10px);
}

.PersonFormRowCommentsTextBoxLabel {
    text-align: left;
    font-size: 15px;
    margin-bottom: 4px;
}

.PersonFormRowCommentsTextBox {
    width: 100%;
    height: 136px;
    resize: none;
    font-size: 13px;
    border-width: 1px;
    border-radius: 4px;
    border-style: solid;
    outline: none;
}

.PersonFormRowCommentsTextBox:focus {
    border-color: #7530BCDC;
}

.PersonFormRowHidden {
     display: none;
}

.PersonFormRowTextBoxesContainer {
    margin-top: 10px;
    width: 100%;
    display: flex;
    padding: 0;
}

@media only screen and (max-width: 416px) {
    .PersonFormRowTextBoxesContainer {
        display: block;
        padding-right: 8px;
    }
    .PersonFormRowDietaryTextBoxContainer {
        margin-right: 0;
        width: calc(100% - 6px);
    }
    .PersonFormRowDietaryTextBox {
        height: 100px;
    }
    .PersonFormRowCommentsTextBoxContainer {
        margin-left: 0;
        width: calc(100% - 6px);
    }
    .PersonFormRowCommentsTextBox {
        height: 100px;
    }
}

